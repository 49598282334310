<template>
    <v-dialog
    v-model="dialog"
    max-width="800px"
    origin="center center"
    persistent
    :fullscreen="isMobile"
    >
        <v-card>
            <v-card-title>
                {{ isUpdating ? "Editar" : "Nova " }} Logística
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-show="!loading">
                <v-form v-model="formValid" ref="form" >
                    <div class="row col-xs mt-2">
                        <div class="col-md-4">
                            <v-text-field
                                v-model="form.name"
                                :rules="[rules.required]"
                                label="Nome da operação"
                                outlined
                                dense
                                hide-details="auto"
                            />
                        </div>
                        <div class="col-md-4">
                          <v-text-field
                              v-model="form.zip_code"
                              :rules="[rules.required]"
                              v-mask="'########'"
                              label="CEP"
                              outlined
                              dense
                              hide-details="auto"
                              @change="checkCep"
                          />
                      </div>
                      <div class="col-md">
                        <v-text-field
                          v-model="form.number"
                          :rules="[rules.required]"
                          label="Número"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="row col-xs">
                        <div class="col-md-4">
                            <v-text-field
                                v-model="form.neighborhood"
                                :rules="[rules.required]"
                                label="Bairro"
                                outlined
                                dense
                                hide-details="auto"
                            />
                        </div>
                        <div class="col-md-4">
                            <v-text-field
                              v-model="form.city"
                              :rules="[rules.required]"
                              label="Cidade"
                              outlined
                              dense
                              hide-details="auto"
                            ></v-text-field>
                        </div>
                        <div class="col-md">
                            <v-text-field
                              v-model="form.state"
                              :rules="[rules.required]"
                              label="Estado"
                              outlined
                              dense
                              hide-details="auto"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="row col-xs">
                      <div class="col-md-4">
                          <v-text-field
                              v-model="form.street"
                              :rules="[rules.required]"
                              label="Rua"
                              outlined
                              dense
                              hide-details="auto"
                          />
                      </div>
                      <div class="col-md">
                        <v-text-field
                          v-model="form.description"
                          label="Descrição"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-text-field>
                    </div>
                    </div>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  class="text-capitalize"
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  color="success"
                  @click="submitForm"
                >
                  <v-icon>
                    {{ icons.mdiContentSave }}
                  </v-icon>
                  Salvar Informações
                </v-btn>
              </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mdiContentSave } from "@mdi/js";
import formMixin from '@/util/mixins/formMixin';
import notification from '@/helper/notification'
const axios = require("axios");

export default {
    name: "logisticform",

    mixins: [formMixin],

    data() {
        return {
            loading: false,
            dialog: false,
            isUpdating: false,
            formValid: true,

            form: {
                name: null,
                city: null,
                neighborhood: null,
                number: null,
                zip_code: null,
                state: null,
                street: null,
                description: null,
            },

            icons: {
                mdiContentSave,
            },
        }
    },

    methods: {
        open(id = null) {
            this.dialog = true;
            this.isUpdating = id != null;

            if (id) {
              this.getLogistic(id);
            }
        },

        close(){
            this.dialog = false
        },

        getLogistic(id){
          this.$http
            .$get("logistics/" + id)
            .then((response) => {
              this.form = response.data
            })
            .catch((error) => {
              console.log(error)
            })
        },

        submitForm() {
            if (this.$refs.form.validate()) {
              if (this.isUpdating) {
                this.$http
                  .$put(`/logistics/${this.form.id}`, this.form)
                  .then((res) => {
                    if (res.status == 200) {
                      this.dialog = false;
                      this.$emit("onSubmit");
                    }
                  });
              } else {
                this.$http.$post("/logistics", this.form).then((res) => {
                  if (res.status == 200) {
                    this.dialog = false;
                    this.$emit("onSubmit");
                  }
                });
              }        
            }
        },

      checkCep() {
        axios
          .get(
            "https://brasilapi.com.br/api/cep/v1/" +
            this.form.zip_code.replace("-", "")
          )
          .then((res) => {
            this.form = {
              id: this.form.id,
              name: this.form.name,
              number: this.form.number,
              description: this.form.description,
              zip_code: this.form.zip_code,
              state: res.data.state,
              city: res.data.city,
              neighborhood: res.data.neighborhood,
              street: res.data.street,
            }
          })
          .catch((error) => {
            notification.showError(error);
          });
      }
    },

    watch:{
    dialog(){
      if (!this.dialog) {
        this.$refs.form.reset()
      }
    }
  }
}
</script>

<style>

</style>