<template>
  <v-dialog v-model="isDialogVisible" persistent width="500">
    <v-card>
      <v-card-title class="headline"> Atenção </v-card-title>
      <v-card-text>
        Você deseja Realmete {{ setStatusText }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" outlined @click="isDialogVisible = false">
          Cancelar
        </v-btn>

        <template v-if="this.user.status == false">
          <v-btn color="success" @click="active"> Ativar </v-btn>
        </template>

        <template v-else>
          <v-btn color="error" @click="inactive"> Desativar </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      isDialogVisible: false,
      user: {},
    };
  },

  computed: {
    setStatusText: function () {
      if(this.user.status == 0){
        return "Ativar"
      }
      return "Desativar"
    }
  },

  methods: {
    open(user) {
      this.user = user;
      this.isDialogVisible = true;
    },

    inactive() {
      this.user.status = 0;
      this.$emit("inactive", this.user);
      this.isDialogVisible = false;
    },

    active() {
      this.user.status = 1;
      this.$emit("active", this.user);
      this.isDialogVisible = false;
    },
  },
};
</script>