var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Logísticas")]),(_vm.logistics.length)?_c('v-card-text',{staticClass:"d-flex align-center flex-wrap"},[_c('v-card-text',{staticClass:"px-0"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex align-center d-flex flex-row-reverse"},[_c('v-card-title',{staticClass:"d-flex align-center py-sm-2 py-6"},[_c('div',{staticClass:"row align-center"},[_c('v-btn',{attrs:{"color":"success text-capitalize"},on:{"click":function($event){return _vm.handleLogistic()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDolly))]),_c('span',[_vm._v("Nova Logística")])],1)],1)])],1),_c('v-card-text',{staticClass:"px-0"},[_c('v-data-table',{attrs:{"items-per-page":25,"page":_vm.pageCount,"headers":_vm.logisticsTableColumns,"items":_vm.logistics,"footer-props":{
                            itemsPerPageOptions: [25,50,100,-1],
                            itemsPerPageText: 'Itens por pagina',
                            itemsPerPageAllText: 'Todos',
                            disablePagination: true,
                            nextIcon: '',
                            prevIcon: '',
                            pageText: ''
                        }},on:{"update:page":function($event){_vm.pageCount=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.status)}},[_c('div',[_vm._v(" "+_vm._s(item.status == 1 ? 'Ativo' : 'Inativo')+" ")])])],1)]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleLogistic(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Editar")])],1)],1),(item.status == 1)?_c('v-list-item',{on:{"click":function($event){return _vm.openAlertConfirmation(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Desativar")])],1)],1):_c('v-list-item',{on:{"click":function($event){return _vm.openAlertConfirmation(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiRestore)+" ")]),_c('span',[_vm._v("Ativar")])],1)],1)],1)],1)]}},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}],null,false,1062284272)})],1)],1)],1)],1):_vm._e(),(!_vm.logistics.length && !_vm.loading)?_c('nothing-component',[_c('v-btn',{attrs:{"color":"success text-capitalize ma-4"},on:{"click":function($event){return _vm.handleLogistic()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Nova Logistica")])],1)],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),_c('logistic-form',{ref:"logisticForm",on:{"onSubmit":_vm.getLogistics}}),_c('alert',{ref:"alert",on:{"inactive":function($event){return _vm.updateStatusLogistic($event)},"active":function($event){return _vm.updateStatusLogistic($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }