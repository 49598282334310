<template>
    <div>
        <h1>Logísticas</h1>
        <v-card-text class="d-flex align-center flex-wrap" v-if="logistics.length">
            <v-card-text class="px-0">
                <v-card flat>
                    <v-card-text class="d-flex align-center d-flex flex-row-reverse">
                        <v-card-title
                            class="d-flex align-center py-sm-2 py-6"
                        >
                            <div class="row align-center">
                                <v-btn color="success text-capitalize" @click="handleLogistic()">
                                    <v-icon>{{ icons.mdiDolly }}</v-icon>
                                    <span>Nova Logística</span>
                                </v-btn>
                            </div>
                        </v-card-title>
                    </v-card-text>
                    <v-card-text class="px-0">
                        <v-data-table
                            :items-per-page="25"
                            :page.sync="pageCount"
                            :headers="logisticsTableColumns"
                            :items="logistics"
                            @page-count="pageCount = $event"
                            :footer-props="{
                                itemsPerPageOptions: [25,50,100,-1],
                                itemsPerPageText: 'Itens por pagina',
                                itemsPerPageAllText: 'Todos',
                                disablePagination: true,
                                nextIcon: '',
                                prevIcon: '',
                                pageText: ''
                            }"  
                        >
                        <template v-slot:item.status="{ item }">
                            <div class="d-flex align-center">
                                <v-chip
                                    small
                                    :color="resolveUserStatusVariant(item.status)"
                                    :class="`${resolveUserStatusVariant(item.status)}--text`"
                                    class="v-chip-light-bg font-weight-semibold text-capitalize"
                                >
                                    <div>
                                        {{ item.status == 1 ? 'Ativo' : 'Inativo'}}
                                    </div>
                                </v-chip>
                            </div>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-menu bottom left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item @click="handleLogistic(item.id)">
                                        <v-list-item-title>
                                            <v-icon size="20" class="me-2">
                                                {{ icons.mdiFileDocumentOutline }}
                                            </v-icon>
                                            <span>Editar</span>
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item
                                        v-if="item.status == 1"
                                        @click="openAlertConfirmation(item)"
                                    >
                                        <v-list-item-title>
                                            <v-icon size="20" class="me-2">
                                                {{ icons.mdiDeleteOutline }}
                                            </v-icon>
                                            <span>Desativar</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-else @click="openAlertConfirmation(item)">
                                        <v-list-item-title>
                                            <v-icon size="20" class="me-2">
                                                {{ icons.mdiRestore }}
                                            </v-icon>
                                            <span>Ativar</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <template v-slot:footer class="d-flex">
                            <v-pagination
                                v-model="page"
                                :length="pageCount"
                            ></v-pagination>
                        </template>
                        </v-data-table>    
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card-text>
        <nothing-component v-if="!logistics.length && !loading">
            <v-btn color="success text-capitalize ma-4" @click="handleLogistic()">
                <v-icon>{{ icons.mdiPlus }}</v-icon>
                <span>Nova Logistica</span>
            </v-btn>
        </nothing-component>
        <div v-if="loading" class="d-flex justify-center">
            <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <logistic-form ref="logisticForm" @onSubmit="getLogistics"/>
        <alert
          ref="alert"
          @inactive="updateStatusLogistic($event)"
          @active="updateStatusLogistic($event)"
        ></alert>
    </div>
</template>

<script>
import { mdiDotsVertical, mdiFileDocumentOutline, mdiDeleteOutline, mdiPlus, mdiDolly,mdiRestore } from "@mdi/js";
import NothingComponent from '@/components/nothingComponent.vue';
import LogisticForm from "@/components/logistics/form/LogisticForm.vue";
import Alert from "@/components/app/alert/Alert.vue";
import tableMixin from "@/util/mixins/tableMixin";

export default {
    components: { NothingComponent, LogisticForm, Alert },
    mixins: [tableMixin],
    data() {
        return {
            loading: false,
            logistics: [],

            icons: {
                mdiDotsVertical,
                mdiFileDocumentOutline,
                mdiDeleteOutline,
                mdiPlus,
                mdiDolly,
                mdiRestore,
            },

            logisticsTableColumns: [
                { text: "Nome", value: "name" },
                { text: "Descrição", value: "description" },
                { text: "Cidade", value: "city" },
                { text: "Estado", value: "state" },
                { text: "Status", value: "status" },
                { text: "Ações", value: "actions" }
            ],
            page: null,
            pageCount: null,
        };
    },

    mounted() {
        this.getLogistics();
    },

    methods: {
        getLogistics() {
            this.loading = true
            this.$http
                .$get("logistics")
                .then((response) => {
                    this.logistics = response.data.data
                    this.loading = false
                })
                .catch((error) => {
                    console.log(error)
                });
        },

        handleLogistic(row) {
            this.$refs.logisticForm.open(row);
        },

        updateStatusLogistic(content) {
            this.$http
            .$put(`/logistics/${content.id}`, content)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        },

        openAlertConfirmation(row) {
            this.$refs.alert.open(row);
        },
    },
}
</script>

<style></style>